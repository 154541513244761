import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from '../../../../api';
import { AreaChart } from '../../../../components/Charts/areaChart';
import { BenignLesionsChart } from '../../../../components/Charts/benignLesions';
import { ChartCard } from '../../../../components/Charts/chartCard';
import { GlandularPreNeoplastic } from '../../../../components/Charts/glandularPreNeoplastic';
import { HistopathologyOrigin } from '../../../../components/Charts/histopathologyOrigin';
import { SquamousPreNeoplastic } from '../../../../components/Charts/squamousPreNeoplastic';
import FiltersVisaoGeral from '../../../../components/Filters/FilterVisaoGeral';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import styles from './styles.module.css';

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento', value: '' },
  { inputName: 'equipe', value: '' },
  { inputName: 'periodo', value: '' },
];

const sampleStatusData = [50, 50];

export function VisaoGeral() {
  const [listFilter, setListFilter] = useState<any>(initialStateListFilter);
  const [showListFilter, setShowListFilter] = useState<any>([]);
  const { control } = useForm();
  const [totalTestsPerMonth, setTotalTestsPerMonth] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [benignLesionsChartData, setBenignLesionsChartData] = useState<any>([]);
  const [payload, setPayload] = useState<any>();
  const [total, setTotal] = useState<any>();

  const getTestesPerMonth = async (cleared) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/quantity-month',
      cleared ? {} : payload
    );

    setTotalTestsPerMonth(
      response.data?.data.map((item) => Number(item?.count))
    );
    setCategories(
      response.data?.data.map((item) => String(item?.month)?.toUpperCase())
    );
  };

  const getBenignLesionsChart = async (cleared) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/faixa',
      cleared ? {} : payload
    );

    setBenignLesionsChartData([
      response?.data?.data?.[1]?.count ? response?.data?.data?.[1]?.count : 0,
      response.data?.data?.[0]?.count ? response.data?.data?.[0]?.count : 0,
      response.data?.data?.[3]?.count ? response.data?.data?.[3]?.count : 0,
      response.data?.data?.[2]?.count ? response.data?.data?.[2]?.count : 0,
    ]);
  };

  const getAllCharts = ({ cleared }) => {
    setTotalTestsPerMonth([]);
    setCategories([]);
    setBenignLesionsChartData([]);
    setTotal('');
    getTestesPerMonth(cleared);
    getBenignLesionsChart(cleared);
  };

  useEffect(() => {
    getAllCharts({ cleared: false });
  }, []);

  useEffect(() => {
    setTotal(totalTestsPerMonth.reduce((prev, curr) => prev + curr, 0));
  }, [totalTestsPerMonth]);
  return (
    <Box mt="31px">
      <Box sx={{ marginBottom: '42px' }}>
        <FiltersVisaoGeral
          control={control}
          setShowListFilter={setShowListFilter}
          setPayload={setPayload}
          getAllCharts={getAllCharts}
          setListFilter={setListFilter}
          initialStateListFilter={initialStateListFilter}
          listFilter={listFilter}
        />
      </Box>
      <Box display="flex" flexDirection="column" gap="2px">
        <Typography variant="h5" fontWeight="bold" fontSize="24px">
          Exames de Histopatologia - Visão Geral
        </Typography>
        <Typography variant="h6" fontWeight="bold" fontSize="20px">
          Últimos 12 meses
        </Typography>
        <Typography variant="body1">
          Data de Atualização: 29/01/2024 16:15
        </Typography>
        <DisplayFilter style={{ marginTop: '17px', marginBottom: '37px' }}>
          <p style={{ margin: 0, width: '138px' }}>Filtros aplicados:</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {showListFilter.map((item) => (
              <>
                {item.value ? (
                  <>
                    <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                  </>
                ) : undefined}
              </>
            ))}
          </div>
        </DisplayFilter>
      </Box>

      <div className={styles.testsDoneCard}>
        <div className={styles.testsDoneCardTitle}>
          <Typography variant="h5" fontWeight="bold">
            Exames de Histopatologia <br />
            Quantidade de Exames Realizados
          </Typography>

          <Typography variant="h5" fontWeight="bold">
            {total ? total?.toLocaleString('pt-BR') : ''}
          </Typography>
        </div>

        <AreaChart
          data={totalTestsPerMonth}
          categories={categories}
          payload={null}
        />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ChartCard
            title="Exames de Histopatologia"
            subtitle="Origem"
            fullWidth
          >
            <HistopathologyOrigin data={sampleStatusData} />
          </ChartCard>
        </Grid>

        <Grid item xs={12}>
          <ChartCard
            title="Exames de Histopatologia"
            subtitle="Lesões de Caráter Benigno"
            fullWidth
          >
            <BenignLesionsChart data={benignLesionsChartData} />
          </ChartCard>
        </Grid>

        <Grid item xs={12}>
          <ChartCard
            title="Exames de Histopatologia"
            subtitle="Lesões de Caráter Neoplásico - Pré-Neoplásico Escamosa"
            fullWidth
          >
            <SquamousPreNeoplastic data={[177, 320, 380, 30, 320]} />
          </ChartCard>
        </Grid>

        <Grid item xs={12}>
          <ChartCard
            title="Exames de Histopatologia"
            subtitle="Lesões de Caráter Neoplásico - Pré-Neoplásico Glandular"
            fullWidth
          >
            <GlandularPreNeoplastic data={[177, 320, 380]} />
          </ChartCard>
        </Grid>
      </Grid>
    </Box>
  );
}
