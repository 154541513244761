import { PieChart } from '../pieChart';
import { PieChart2 } from '../pieChart2';

interface ExamResultsChartProps {
  data: number[];
  categories?: String[];
  height?: number;
}

export function ExamResultsChart({ data }: ExamResultsChartProps) {
  return (
    <PieChart2
      data={data}
      height={284}
      labels={[
        `Não detectável (${data[0]})`,
        `Detectável (${data[1]})`,
        `Inconclusivo (${data[2]})`,
      ]}
      colors={['#27C872', '#F4284E', '#ACB7B8']}
      legendWidth={200}
    />
  );
}
