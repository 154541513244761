import { ChartProps } from '../../../types/Chart';
import { BarChart } from '../barChart';

export function BenignLesionsChart({ data }: ChartProps) {
  return (
    <BarChart
      data={data}
      categories={[
        ['Metaplasia', 'Escamosa'],
        ['Pólipo', 'Endocervical'],
        ['Cervicite Crônica', 'Inespecífica'],
        ['Alterações Citoarquiteturais compatíveis', 'com Ação Vital (HPV)s'],
      ]}
      colors={['#54A77B']}
    />
  );
}
