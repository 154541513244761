import { Typography } from '@mui/material';
import styles from './styles.module.css';

const data = [
  {
    municipio: 'Amaraji',
    populacaoElegivel: 6057,
    coletasRealizadas: 714,
    metaColeta: 20,
  },
  {
    municipio: 'Barreiros',
    populacaoElegivel: 11321,
    coletasRealizadas: 586,
    metaColeta: 20,
  },
  {
    municipio: 'Cortês',
    populacaoElegivel: 3250,
    coletasRealizadas: 376,
    metaColeta: 20,
  },
  {
    municipio: 'Lagoa dos Gatos',
    populacaoElegivel: 4142,
    coletasRealizadas: 265,
    metaColeta: 20,
  },
  {
    municipio: 'Primavera',
    populacaoElegivel: 3857,
    coletasRealizadas: 313,
    metaColeta: 20,
  },
  {
    municipio: 'Ribeirão',
    populacaoElegivel: 13035,
    coletasRealizadas: 346,
    metaColeta: 20,
  },
  {
    municipio: 'São Benedito do Sul',
    populacaoElegivel: 4392,
    coletasRealizadas: 177,
    metaColeta: 20,
  },
  {
    municipio: 'Tamandaré',
    populacaoElegivel: 6242,
    coletasRealizadas: 224,
    metaColeta: 20,
  },
];

const calcularTotal = () => {
  const total = {
    populacaoElegivel: 0,
    coletasRealizadas: 0,
    metaColeta: 20,
  };

  data.forEach((row) => {
    total.populacaoElegivel += row.populacaoElegivel;
    total.coletasRealizadas += row.coletasRealizadas;
    total.metaColeta += row.metaColeta;
  });

  return total;
};

const total = calcularTotal();

export function TableResultsDesktop() {
  return (
    <div className={styles.container}>
      <Typography variant="h5" fontWeight="bold" fontSize="20px">
        Exames de Citologia - Reflexo (após teste de HPV Positivo)
        <br />
        Mulheres com HPV Outros x Citologias Realizadas
      </Typography>

      <Typography className={styles.info}>Fonte de Dados: Jordana</Typography>

      <div className={styles.tableWrapper}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>Município</th>
              <th>Mulheres com HPV Outros</th>
              <th>Citologias Realizadas</th>
              <th>Citologias a Realizar</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td>{row.municipio}</td>
                <td>{row.populacaoElegivel}</td>
                <td>{row.coletasRealizadas}</td>
                <td>{row.metaColeta}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ textAlign: 'right' }}>Total</td>
              <td>{total.populacaoElegivel}</td>
              <td>{total.coletasRealizadas}</td>
              <td>{total.metaColeta}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}
