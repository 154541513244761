import { ApexOptions } from 'apexcharts';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { DataSource } from '../dataSource';
import styles from './styles.module.css';

interface AreaChartProps {
  data: number[];
  categories: String[];
  height?: number;
  payload: any;
  total?: number;
}

export function AreaChart({
  data,
  categories,
  height,
  payload,
  total,
}: AreaChartProps) {
  const [chartData, setChartData] = useState<ApexOptions>();
  useEffect(() => {
    if (data && categories.length > 0) {
      setChartData({
        series: [
          {
            name: 'Testes Realizados',
            data,
          },
        ],
        dataLabels: {
          enabled: false,
        },
        chart: {
          height: height || 145,
          type: 'area',
          toolbar: {
            show: false,
            tools: {
              download: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              reset: '',
            },
          },
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: 'smooth',
        },
        xaxis: {
          categories,
          labels: {
            rotate: 0,
          },
        },
        colors: ['#00E396'],
        grid: {
          show: false,
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
        yaxis: {
          min: 0,
          labels: {
            show: false,
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0,
            opacityTo: 0.8,
          },
        },
        responsive: [
          {
            breakpoint: 768,
            options: {
              xaxis: {
                labels: {
                  style: {
                    fontSize: '10px',
                  },
                },
              },
            },
          },
        ],
      });
    }
  }, [categories, data, height]);
  return (
    <>
      {chartData && categories?.length > 0 ? (
        <div
          style={{
            padding: '0 16px',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
          }}
        >
          <p style={{ display: 'none' }} data-testid="totalTestsRealized">
            {total?.toLocaleString('pt-BR')}
          </p>
          <Chart
            options={chartData}
            series={chartData.series}
            type={chartData.chart?.type || 'area'}
            height={height || 145}
          />

          <>
            <div className={styles.monthsContainer}>
              {chartData.xaxis?.categories.map((category: string) => (
                <span key={category}>{category} </span>
              ))}
            </div>
          </>

          {/* <DataSource /> */}
        </div>
      ) : undefined}
    </>
  );
}
