import { Box, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import api from '../../../../api';
import { ChartCard } from '../../../../components/Charts/chartCard';
import { ExamResultsChart } from '../../../../components/Charts/examResultsChart';
import FiltersVisaoGeral from '../../../../components/Filters/FilterVisaoGeral';
import { DisplayFilter, ItemsDisplayFilter } from '../../../Convocation/styles';
import { TableResultsDesktop } from '../components/tableResults/tableDesktop';
import { getWindowDimensions } from '../../../../utils/getWindowDimensions';
import { TableResultsMobile } from '../components/tableResults/tableMobile';
import { AreaChart } from '../../../../components/Charts/areaChart';
import styles from './styles.module.css';

const initialStateListFilter = [
  { inputName: 'estado', value: '' },
  { inputName: 'municipio', value: '' },
  { inputName: 'estabelecimento', value: '' },
  { inputName: 'equipe', value: '' },
  { inputName: 'periodo', value: '' },
];

export function VisaoGeral() {
  const [listFilter, setListFilter] = useState<any>(initialStateListFilter);
  const [showListFilter, setShowListFilter] = useState<any>([]);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const [isMobile, setIsMobile] = useState(false);
  const { control } = useForm();
  const [totalTestsPerMonth, setTotalTestsPerMonth] = useState<any>([]);
  const [categories, setCategories] = useState<any>([]);
  const [payload, setPayload] = useState<any>();
  const [total, setTotal] = useState<any>();

  const getTestesPerMonth = async (cleared) => {
    const response = await api.get(
      '/dashboard/exames/hpv/panel/quantity-month',
      cleared ? {} : payload
    );

    setTotalTestsPerMonth(
      response.data?.data.map((item) => Number(item?.count))
    );
    setCategories(
      response.data?.data.map((item) => String(item?.month)?.toUpperCase())
    );
  };

  const getAllCharts = ({ cleared }) => {
    setTotalTestsPerMonth([]);
    setCategories([]);
    setTotal('');
    getTestesPerMonth(cleared);
  };

  useEffect(() => {
    getAllCharts({ cleared: false });
  }, []);

  useEffect(() => {
    setTotal(totalTestsPerMonth.reduce((prev, curr) => prev + curr, 0));
  }, [totalTestsPerMonth]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    if (windowDimensions.width < 768) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowDimensions]);
  return (
    <Box mt="31px">
      <Box sx={{ marginBottom: '42px' }}>
        <FiltersVisaoGeral
          control={control}
          setShowListFilter={setShowListFilter}
          setPayload={setPayload}
          getAllCharts={getAllCharts}
          setListFilter={setListFilter}
          initialStateListFilter={initialStateListFilter}
          listFilter={listFilter}
        />
      </Box>

      <Box display="flex" flexDirection="column" gap="2px">
        <Typography variant="h5" fontWeight="bold" fontSize="24px">
          Exames de Citologia - Reflexo (após teste de HPV Positivo) - Visão
          Geral
        </Typography>
        <Typography variant="h6" fontWeight="bold" fontSize="20px">
          Últimos 12 meses
        </Typography>
        <Typography variant="body1">
          Data de Atualização: 29/01/2024 16:15
        </Typography>
        <DisplayFilter style={{ marginTop: '17px', marginBottom: '37px' }}>
          <p style={{ margin: 0, width: '138px' }}>Filtros aplicados:</p>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              width: '100%',
              gap: '10px',
              flexWrap: 'wrap',
            }}
          >
            {showListFilter.map((item) => (
              <>
                {item.value ? (
                  <>
                    <ItemsDisplayFilter>{item.value}</ItemsDisplayFilter>
                  </>
                ) : undefined}
              </>
            ))}
          </div>
        </DisplayFilter>
      </Box>

      <div className={styles.testsDoneCard}>
        <div className={styles.testsDoneCardTitle}>
          <Typography variant="h5" fontWeight="bold">
            Exames de Citologia - Reflexo
            <br />
            Quantidade de Exames Realizados
          </Typography>

          <Typography variant="h5" fontWeight="bold">
            {total ? total?.toLocaleString('pt-BR') : ''}
          </Typography>
        </div>

        <AreaChart
          data={totalTestsPerMonth}
          categories={categories}
          payload={null}
        />
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box mt="28px">
            {isMobile ? <TableResultsMobile /> : <TableResultsDesktop />}
          </Box>
        </Grid>

        <Grid item xs={12}>
          <ChartCard
            title="Exames de Citologia - Reflexo"
            subtitle="Resultado do Exame"
            fullWidth
          >
            <div className="chartOnCenter">
              <ExamResultsChart data={[32, 20, 20]} />
            </div>
          </ChartCard>
        </Grid>
      </Grid>
    </Box>
  );
}
