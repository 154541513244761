import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TableUserManagements from '../../components/TableUserManagements/TableUserManagements';
import { ContainerChips, useStyles } from './UserManagement.style';
import { SideMenu } from '../../components/SideMenu';
import api from '../../api';
import { Link } from 'react-router-dom';
import ReturnButton from '../../components/ReturnButton';
import { getUserToken } from '../../lib/auth';
import { StyledAsteriskTextField } from '../../components/StyledAsteriskTextField/styles';

interface ResponseData {
  ativos: string;
  count: number;
  data: [];
  inativos: string;
  page: number;
  pendentes: string;
  totalItems: number;
  totalPages: number;
}

interface User {
  id: number;
  email: string;
  nome_de_usuario: string;
  perfil: {
    status: number;
    descricao: string;
  };
  pessoa: {
    cns: string;
    nome: string;
    sexo: string;
    estabelecimento_saude?: {
      nome_fantasia: string;
    };
  };
}

interface UserSet {
  ativos: string;
  inativos: string;
  pendentes: string;
}

export function UserManagement() {
  const styles = useStyles();
  const [loading] = React.useState(false);
  const [users, setUsers] = React.useState<any | ResponseData>([]);
  const [usersStatus, setUsersStatus] = React.useState({
    ativos: '',
    inativos: '',
    pendentes: '',
  });
  const [nome, setNome] = React.useState('');
  const [statesOptions, setStatesOptions] = React.useState([]);
  const [permissoes, setPermissoes] = React.useState([]);
  const [permissaoSelected, setPermissaoSelected] = React.useState<any>();

  const [municipiosOptions, setMunicipiosOptions] = React.useState([]);
  const [payloadFilter, setPayloadFilter] = React.useState({
    page: 1,
    size: 25,
  });

  // const getUsers = async (
  //   page?: number,
  //   size?: number,
  //   nome?: string,
  //   cidade?: string,
  //   estado?: string
  // ) => {
  //   const response = await api.get(
  //     `/usuarios?nome=${nome ?? ''}&page=${page ?? ''}&size=${
  //       size ?? ''
  //     }&cidade=${cidade ?? ''}&estado=${estado ?? ''}&mostrarTodos=todos`
  //   );

  //   setUsers(response.data);

  //   const responseData: ResponseData = response.data;
  //   const usersData = responseData.data.map((user: User) => {
  //     return {
  //       id: user.id,
  //       email: user.email,
  //       nome_de_usuario: user.nome_de_usuario,
  //       cns: user.pessoa.cns,
  //       nome: user.pessoa.nome,
  //       sexo: user.pessoa.sexo,
  //       status: user?.perfil?.status,
  //       descricao: user?.perfil?.descricao,
  //       estabelecimento: user?.pessoa?.estabelecimento_saude?.nome_fantasia,
  //     };
  //   });

  //   const { ativos, inativos, pendentes } = response.data as UserSet;
  //   setUsersStatus({
  //     ativos,
  //     inativos,
  //     pendentes,
  //   });
  // };

  // const searchUser = async () => {
  //     const response = await api.get('/usuarios', {
  //       params: payloadFilter,
  //     });
  //     setUsers(response.data);
  //     setUsersStatus({ ...response.data } as UserSet);
  //   };
  // React.useEffect(() => {
  //
  //   getUsers();
  // }, [payloadFilter]);

  const handleSearch = async () => {
    const response = await api.get('/usuarios', {
      params: payloadFilter,
    });
    setUsers(response.data);
    setUsersStatus({ ...response.data } as UserSet);
  };

  const handleChangeState = async (newValue) => {
    if (newValue) {
      const responseMunicipios = await api.get('/municipios', {
        params: { sigla: newValue.sigla },
      });
      setPayloadFilter((prev) => {
        return {
          ...prev,
          estado: newValue.sigla,
          cidade: null,
        };
      });
      setMunicipiosOptions(responseMunicipios.data);
    } else {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          estado: null,
          cidade: null,
        };
      });
    }
  };

  const handleChangeMunicipio = async (newValue) => {
    if (newValue) {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          cidade: newValue.nome,
        };
      });
    } else {
      setPayloadFilter((prev) => {
        return {
          ...prev,
          cidade: null,
        };
      });
    }
  };

  const loadPermissoes = async () => {
    try {
      const response = await api.get('/permissoes');

      if (getUserToken().permissao_atual.id === 6) {
        setPermissoes(
          response.data.filter(
            (permissao) => permissao.contexto === 'LABORATÓRIO'
          )
        );
      } else {
        setPermissoes(response.data);
      }
    } catch (error) {}
  };

  React.useEffect(() => {
    loadPermissoes();
  }, []);

  React.useEffect(() => {
    const getStates = async () => {
      const response = await api.get('/estados');
      if (response.status === 200) {
        setStatesOptions(response.data);
      }
    };
    getStates();
  }, []);

  const getUsers = async () => {
    const response = await api.get('/usuarios', {
      params: payloadFilter,
    });
    setUsers(response.data);
    setUsersStatus({ ...response.data } as UserSet);
  };

  React.useEffect(() => {
    getUsers();
  }, [payloadFilter]);

  if (loading) {
    return <h1>Carregando...</h1>;
  } else {
    return (
      <SideMenu>
        <Container>
          <Box mt={12}>
            <ReturnButton backPage="/home" />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Typography variant="h4" fontWeight="bold">
                  Gerenciamento de Usuários
                </Typography>
                <Breadcrumbs
                  separator={<NavigateNextIcon fontSize="small" />}
                  aria-label="breadcrumb"
                  style={{ marginBottom: '16px', marginTop: '16px' }}
                  sx={{ marginBottom: '24px' }}
                >
                  <Link
                    style={{
                      textDecoration: 'none',
                      color: '#646464',
                    }}
                    to="/home"
                  >
                    Home
                  </Link>
                  <Typography key="3" color="text.primary">
                    Gerenciamento de Usuários
                  </Typography>
                </Breadcrumbs>
              </div>
            </div>
            <Divider />
            <Box mt={4} mb={4}>
              <form>
                <Grid container spacing={2} justifyContent="end">
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <TextField
                      label="Pesquisar nome de usuário"
                      size="small"
                      variant="outlined"
                      className={styles.textField}
                      value={nome}
                      onChange={({ target }) => {
                        setNome(target.value);
                        if (target.value) {
                          setPayloadFilter((prev) => {
                            return { ...prev, nome: target.value };
                          });
                        }
                      }}
                      InputProps={{
                        endAdornment: (
                          <Button
                            sx={{
                              marginRight: '1px',
                              backgroundColor: 'white',
                              zIndex: 1,
                            }}
                          >
                            <SearchIcon sx={{ color: 'gray' }} />
                          </Button>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Autocomplete
                      options={statesOptions}
                      renderOption={(props, option: any) => (
                        <li {...props}>{option.nome}</li>
                      )}
                      onChange={(e, newValue) => {
                        handleChangeState(newValue);
                      }}
                      getOptionLabel={(option) => option.nome}
                      isOptionEqualToValue={(option) => option.nome}
                      renderInput={(params) => (
                        <TextField {...params} label="Estado" size="small" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Autocomplete
                      options={municipiosOptions}
                      getOptionLabel={(option: any) => option.nome}
                      onChange={(e, newValue) => {
                        handleChangeMunicipio(newValue);
                      }}
                      renderOption={(props, option: any) => (
                        <li {...props}>{option.nome}</li>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label="Município" size="small" />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Autocomplete
                      value={permissaoSelected ? permissaoSelected : null}
                      onChange={(_, newValue) => {
                        if (newValue) {
                          setPermissaoSelected(newValue);
                          setPayloadFilter((prev) => {
                            return {
                              ...prev,
                              permissaoId: newValue?.id,
                              permissaoContexto: newValue?.contexto,
                            };
                          });
                        } else {
                          setPermissaoSelected(null);
                          setPayloadFilter((prev) => {
                            return {
                              ...prev,
                              permissaoId: null,
                              permissaoContexto: null,
                            };
                          });
                        }
                      }}
                      options={
                        permissoes
                          ? permissoes
                          : [
                              {
                                id: 0,
                                descricao: '',
                                contexto: '',
                                label: 'Não há opções',
                              },
                            ]
                      }
                      getOptionLabel={(option: any) =>
                        `${option.contexto} - ${option.nome}`
                      }
                      renderInput={(params) => (
                        <StyledAsteriskTextField
                          {...params}
                          // required={getValues().status[indexer]}
                          fullWidth
                          size="small"
                          label="Perfil do Usuário"
                          variant="outlined"
                          className={styles.textField}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={3}>
                    <Autocomplete
                      options={municipiosOptions}
                      getOptionLabel={(option: any) => option.nome}
                      onChange={(e, newValue) => {
                        handleChangeMunicipio(newValue);
                      }}
                      renderOption={(props, option: any) => (
                        <li {...props}>{option.nome}</li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Estabelecimento Vinculado"
                          size="small"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={handleSearch}
                      sx={{ padding: '8px 22px' }}
                      variant="contained"
                    >
                      Filtrar
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      sx={{ padding: '8px 22px' }}
                      variant="outlined"
                      color="error"
                    >
                      Limpar
                    </Button>
                  </Grid>
                </Grid>
              </form>

              <ContainerChips>
                <Box
                  component="div"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography style={{ fontSize: '13px' }}>
                    Usuários Ativos
                  </Typography>
                  <Chip
                    color="success"
                    id="ativos"
                    style={{ margin: '0px 35px 0px 10px' }}
                    label={usersStatus.ativos}
                  />
                </Box>
                <Box
                  component="div"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography style={{ fontSize: '13px' }}>
                    Usuários Pendentes
                  </Typography>
                  <Chip
                    color="warning"
                    label={usersStatus.pendentes}
                    style={{ margin: '0px 35px 0px 10px' }}
                  />
                </Box>
                <Box
                  component="div"
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Typography style={{ fontSize: '13px' }}>
                    Usuários Inativos
                  </Typography>
                  <Chip
                    color="error"
                    label={usersStatus.inativos}
                    style={{ margin: '0px 35px 0px 10px' }}
                  />
                </Box>
              </ContainerChips>
            </Box>
            <TableUserManagements
              getUsers={getUsers}
              list={users}
              setPayloadFilter={setPayloadFilter}
              //list={{ data: users }}
              nome={nome}
            />
          </Box>
        </Container>
      </SideMenu>
    );
  }
}
